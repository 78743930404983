const menuItems = {
  items: [
    {
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          icon: 'feather icon-home',
          url: '/dashboard'
        },
        {
          id: 'administration',
          title: 'Administration',
          type: 'collapse',
          icon: 'feather icon-bar-chart-2',
          children: [
            {
              id: 'supplyManagement',
              title: 'Supply Management',
              type: 'item',
              url: '/administration/supply-management',
            },
            {
              id: 'inventoryCheck',
              title: 'Inventory Check',
              type: 'item',
              url: '/administration/inventory-check',
            }
          ]
        },
        {
          id: 'financial',
          title: 'Financial',
          type: 'collapse',
          icon: 'feather icon-activity',
          children: [
            {
              id: 'managePr',
              title: 'Manage P.R',
              type: 'item',
              url: '/financial/manage-pr'
            },
            {
              id: 'payAndReceive',
              title: 'Pay and Receive',
              type: 'item',
              url: '/financial/pay-and-receive'
            },
            {
              id: 'manageCustomerChecks',
              title: 'Manage Customer Checks',
              type: 'item',
              url: 'pg_check_customer.php'
            },
            {
              id: 'manageSupplierChecks',
              title: 'Manage Supplier Checks',
              type: 'item',
              url: 'pg_check_supplier.php'
            }
          ]
        },
        {
          id: 'tools',
          title: 'Tools',
          type: 'collapse',
          icon: 'feather icon-airplay',
          children: [
            {
              id: 'ebayCompare',
              title: 'Ebay Compare',
              type: 'item',
              url: 'pg_ebayCompare.php'
            },
            {
              id: 'ebayOrders',
              title: 'Ebay Orders',
              type: 'item',
              url: 'pg_ebayOrders.php'
            },
            {
              id: 'ebayInventory',
              title: 'Ebay Inventory',
              type: 'item',
              url: 'pg_ebayInventory.php'
            },
            {
              id: 'onlineDemand',
              title: 'Online Demand',
              type: 'item',
              url: 'pg_online_demand.php'
            },
            {
              id: 'pricing',
              title: 'Pricing',
              type: 'item',
              url: 'pg_pricing.php'
            },
            {
              id: 'checkShipping',
              title: 'Check Shipping',
              type: 'item',
              url: 'pg_checkShipping.php'
            },
            {
              id: 'managerGroups',
              title: 'Manager Groups',
              type: 'item',
              url: 'pg_groups.php'
            },
            {
              id: 'managerTransactions',
              title: 'Manager Transactions',
              type: 'item',
              url: 'pg_manager_transaction.php'
            },
            {
              id: 'suggestionToOrder',
              title: 'Suggestion To Order',
              type: 'item',
              url: 'pg_suggestion_to_order.php'
            }
          ]
        },
        {
          id: 'inventoryManager',
          title: 'Inventory Manager',
          type: 'collapse',
          icon: 'feather icon-box',
          children: [
            {
              id: 'itemsInventory',
              title: 'Items Inventory',
              type: 'item',
              url: 'pg_itemInventory.php'
            },
            {
              id: 'barcodes',
              title: 'Barcodes',
              type: 'item',
              url: 'pg_barcode.php'
            },
            {
              id: 'brands',
              title: 'Brands',
              type: 'item',
              url: 'pg_brand.php'
            },
            {
              id: 'capacities',
              title: 'Capacities',
              type: 'item',
              url: 'pg_capacity.php'
            },
            {
              id: 'categories',
              title: 'Categories',
              type: 'item',
              url: 'pg_category.php'
            },
            {
              id: 'colors',
              title: 'Colors',
              type: 'item',
              url: 'pg_color.php'
            },
            {
              id: 'channels',
              title: 'Channels',
              type: 'item',
              url: 'pg_channel.php'
            },
            {
              id: 'rams',
              title: 'Rams',
              type: 'item',
              url: 'pg_ram.php'
            },
            {
              id: 'conditions',
              title: 'Conditions',
              type: 'item',
              url: 'pg_condition.php'
            },
            {
              id: 'specs',
              title: 'Specs',
              type: 'item',
              url: 'pg_specs.php'
            },
            {
              id: 'inactiveItems',
              title: 'Inactive Items',
              type: 'item',
              url: 'pg_itemInventory.php?status=0'
            },
            {
              id: 'suggestiveToInactivate',
              title: 'Suggestive to Inactivate',
              type: 'item',
              url: 'pg_itemToInactive.php'
            },
            {
              id: 'openBox',
              title: 'Open Box',
              type: 'item',
              url: 'pg_openbox.php'
            },
            {
              id: 'phonesInfo',
              title: 'Phones Info',
              type: 'item',
              url: 'pg_phones_info.php'
            }
          ]
        },
        {
          id: 'invoiceBill',
          title: 'Invoice / Bill',
          type: 'collapse',
          icon: 'feather icon-clipboard',
          children: [
            {
              id: 'bill',
              title: 'Bill',
              type: 'item',
              url: 'pg_bill.php'
            },
            {
              id: 'invoice',
              title: 'Invoice',
              type: 'item',
              url: 'pg_invoice.php'
            }
          ]
        },
        {
          id: 'salesOrderPO',
          title: 'Sales Order / P.O',
          type: 'collapse',
          icon: 'feather icon-file-text',
          children: [
            {
              id: 'salesOrder',
              title: 'Sales Order',
              type: 'item',
              url: 'pg_quotation.php'
            },
            {
              id: 'quotation',
              title: 'Quotation',
              type: 'item',
              url: 'pg_draft.php'
            },
            {
              id: 'purchaseOrder',
              title: 'P.O',
              type: 'item',
              url: 'pg_purchaseOrder.php'
            }
          ]
        },
        {
          id: 'creditReturn',
          title: 'Credit / Return',
          type: 'collapse',
          icon: 'feather icon-repeat',
          children: [
            {
              id: 'customerCredit',
              title: 'Customer Credit',
              type: 'item',
              url: 'pg_customer_credit.php'
            },
            {
              id: 'supplierCredit',
              title: 'Supplier Credit',
              type: 'item',
              url: 'pg_supplier_credit.php'
            },
            {
              id: 'returns',
              title: 'Returns',
              type: 'item',
              url: 'pg_return_supplier.php'
            }
          ]
        },
        {
          id: 'customersSuppliers',
          title: 'Customers / Suppliers',
          type: 'collapse',
          icon: 'feather icon-users',
          children: [
            {
              id: 'customers',
              title: 'Customers',
              type: 'item',
              url: 'pg_customers.php'
            },
            {
              id: 'suppliers',
              title: 'Suppliers',
              type: 'item',
              url: 'pg_suppliers.php'
            }
          ]
        },
        {
          id: 'reports',
          title: 'Reports',
          type: 'item',
          icon: 'feather icon-printer',
          url: 'pg_reports.php'
        },
        {
          id: 'returns',
          title: 'Returns',
          type: 'item',
          icon: 'feather icon-package',
          url: 'pg_returns.php'
        },
        {
          id: 'rma',
          title: 'RMA',
          type: 'collapse',
          icon: 'feather icon-settings',
          children: [
            {
              id: 'rma',
              title: 'RMA',
              type: 'item',
              url: 'pg_rma.php'
            }
          ]
        },
        {
          id: 'inventoryTransactions',
          title: 'Inventory Transactions',
          type: 'collapse',
          icon: 'feather icon-menu',
          children: [
            {
              id: 'addToBill',
              title: 'Add To Bill',
              type: 'item',
              url: 'pg_addBill.php'
            },
            {
              id: 'addToBill2D',
              title: 'Add To Bill - 2D',
              type: 'item',
              url: 'pg_addBill_2d.php'
            },
            {
              id: 'addCustomerCredit',
              title: 'Add Customer Credit',
              type: 'item',
              url: 'pg_addCustomerCredit.php'
            },
            {
              id: 'addReturn',
              title: 'Add Return',
              type: 'item',
              url: 'pg_addReturn.php'
            },
            {
              id: 'addToInvoice',
              title: 'Add To Invoice',
              type: 'item',
              url: 'pg_addInvoice.php'
            },
            {
              id: 'addToInvoice2D',
              title: 'Add To Invoice - 2D',
              type: 'item',
              url: 'pg_addInvoice_2d.php'
            },
            {
              id: 'addSupplierCredit',
              title: 'Add Supplier Credit',
              type: 'item',
              url: 'pg_addSupplierCredit.php'
            },
            {
              id: 'transactions',
              title: 'Transactions',
              type: 'item',
              url: 'pg_transactions.php'
            }
          ]
        },
      ]
    },
    {
      id: 'shop',
      title: 'Shop',
      type: 'group',
      icon: 'icon-support',
      children: [
        {
          id: 'backOffice',
          title: 'BackOffice Shop',
          type: 'collapse',
          icon: 'feather icon-shopping-cart',
          classes: 'disabled',
          children: [
            {
              id: 'orders',
              title: 'Orders',
              type: 'item',
              url: 'pg_backoffice_orders.php'
            },
            {
              id: 'resellers',
              title: 'Resellers',
              type: 'item',
              url: 'pg_resellers.php'
            }
          ]
        }
      ]
    }
  ]
};

export default menuItems;
