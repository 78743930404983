const friends = [
  {
    id: 22,
    photo: 'avatar-2.jpg',
    name: 'Lary Doe',
    new: 1,
    status: 1,
    time: 'online'
  },
  {
    id: 50,
    photo: 'avatar-2.jpg',
    name: 'Vitor Pereiraaaaaaaaaa',
    new: 1,
    status: 1,
    time: 'online'
  },
  {
    id: 23,
    photo: 'avatar-3.jpg',
    name: 'Alice',
    status: 1,
    time: 'online'
  },
  {
    id: 24,
    photo: 'avatar-1.jpg',
    name: 'Alia',
    status: 0,
    new: 1,
    time: '10 min ago'
  },
  {
    id: 25,
    photo: 'avatar-4.jpg',
    name: 'Suzen',
    status: 0,
    time: '15 min ago'
  }
];

export default friends;
