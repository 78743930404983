import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Loader from './components/Loader/Loader';
import AdminLayout from './layouts/AdminLayout';

import GuestGuard from './components/Auth/GuestGuard';
import AuthGuard from './components/Auth/AuthGuard';

import { BASE_URL } from './config/constant';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/signin',
    component: lazy(() => import('./views/auth/signin/SignIn'))
  },
  {
    exact: true,
    path: '/auth/signup',
    component: lazy(() => import('./views/auth/signup/SignUp'))
  },
  {
    exact: true,
    path: '/auth/reset-password',
    component: lazy(() => import('./views/auth/reset-password/ResetPassword'))
  },
  {
    path: '*',
    layout: AdminLayout,
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: '/dashboard',
        component: lazy(() => import('./views/Dashboard'))
      },
      {
        exact: true,
        path: '/administration/supply-management',
        component: lazy(() => import('./views/Administration/SupplyManagement'))
      },
      {
        exact: true,
        path: '/administration/inventory-check',
        component: lazy(() => import('./views/Administration/InventoryCheck'))
      },
      {
        exact: true,
        path: '/financial/manage-pr',
        component: lazy(() => import('./views/Financial/ManagerPr'))
      },
      {
        exact: true,
        path: '/financial/pay-and-receive',
        component: lazy(() => import('./views/Financial/PayAndReceive'))
      },
      {
        path: '*',
        exact: true,
        component: () => <Redirect to={BASE_URL} />
      }
    ]
  }
];

export default routes;
